@import "variables.custom";

.col-1-5 {
  flex: 0 0 auto;
  width: 12.5%;
}

.col-2-5 {
  flex: 0 0 auto;
  width: 20.8%;
}

.spinner-custom {
  width: 80px;
  height: 80px;
}

.size-icon-check {
  font-size: 124px;
}

.pre-line {
  white-space: pre-line !important;
}

.status-copy-success {
  color: $gori-color-d !important;
  background-color: #e2e0ff !important;
}

.border-invalid {
  border-color: #f1416c !important;
}

.table-light-td-first > tr > td:first-child {
  background-color: #f5f8fa;
}

.table-tr-td > tr > td {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
