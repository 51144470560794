@import "variables.custom";
@import "../core/components/variables";

html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.modal-open) {
  overflow-y: inherit !important;

  ::-webkit-scrollbar {
    color: transparent !important;
    background-color: #999999 !important;
  }
}
