//
// Root
//

// Light mode
@include theme-light() {
  // Page bg
  --kt-page-bg: #{$page-bg};

  // Header
  --kt-header-bg-color: #{get($header-config, bg-color)};
  --kt-header-border-bottom: #{get($header-config, border-bottom)};
  --kt-header-box-shadow: #{get($header-config, box-shadow)};

  // Footer
  --kt-footer-bg-color: #{get($footer-config, bg-color)};
}

// Dark mode
@include theme-dark() {
  // Page bg
  --kt-page-bg: #{$page-bg-dark};

  // Header
  --kt-header-bg-color: #{get($header-config, bg-color-dark)};
  --kt-header-border-bottom: #{get($header-config, border-bottom-dark)};
  --kt-header-box-shadow: #{get($header-config, box-shadow-dark)};

  // Footer
  --kt-footer-bg-color: #{get($footer-config, bg-color-dark)};
}
